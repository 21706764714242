.sir-dashboard {
  &-card {
    @include spacing-inset-40;
  }

  &-logo {
    width: 120px;
    margin-left: 3px;
  }

  &-card-content {
    align-items: flex-start;

    @include at-screen-large {
      display: flex;
    }

    &--primary {
      @include at-screen-large {
        padding-right: $spacing-inline-64;
      }

      .sir-type-heading {
        margin: $spacing-stack-16 0;

        br {
          display: none;

          @include at-screen-large {
            display: block;
          }
        }
      }
    }

    &--secondary {
      display: none;
      flex-shrink: 0;
      margin-top: $spacing-inline-40;

      @include at-screen-large {
        display: block;
      }

      .sir-report-preview-image {
        width: 380px;
      }
    }
  }

  &-card-grid-wrapper {
    padding: $spacing-stack-40 0;

    .sir-type-display--small {
      margin-bottom: $spacing-stack-24;
    }
  }

  &-card-grid {
    display: grid;
    grid-template-rows: auto;
    grid-gap: $spacing-stack-16;

    @include at-screen-large {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: $spacing-stack-40 $spacing-inline-16;
    }
  }

  &-resource-card {
    @include spacing-squish-16-24;

    &-header {
      margin-bottom: $spacing-stack-16;
    }

    &-body {
      display: flex;
      flex-flow: column wrap;

      > .sir-link {
        margin-bottom: $spacing-stack-4;
      }
    }
  }
}

.sir-state-select-form {
  align-items: flex-end;
  margin-top: $spacing-stack-32;

  @include at-screen-medium {
    display: flex;
  }

  .sir-form-field {
    flex: 0 1 320px;
  }

  .sir-btn--primary {
    flex: 0 0 188px;
    margin-top: $spacing-stack-16;

    @include at-screen-medium {
      margin-top: 0;
      margin-left: $spacing-inline-24;
    }
  }
}
